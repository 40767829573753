body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: auto;
}

.logoMobil {
  width: 100%;
  max-width: 150px;
}

a {
  cursor: pointer
}

@font-face {
  font-family: 'Archivo';
  src: local('Archivo'), url(./fonts/Archivo-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'ArchivoItalic';
  src: local('ArchivoItalic'), url(./fonts/Archivo-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'ArchivoBold';
  src: local('ArchivoBold'), url(./fonts/Archivo-Bold.ttf) format('truetype');
}

:root {
  --bgImage: url("https://app.simplitpos.com/img/Isotipo-SimplitPOS.png");
  --SimplitPOSiso: url('https://totalaplicaciones.com/emailimages/simplitpos/simplitposiso.png');
  --bgImage-white: url("https://simplitpos.com/img/SimplitPOS_1White.png");
  /* --bgImage-chat: url("C:/Users/DESARROLLO07/simplitposappv2/src/proyectImages/backgroundChat.jpg"); */
  --main-bg-color0: #FFFFFF;
  --main-bg-color1: #848181;
  --main-bg-color2: #ece8e8;
  --main-bg-color3: #fe7c00;
  --main-bg-color4: #3679E9;
  --main-bg-color5: #679635;
  --main-bg-color6: #F7E0D8;
  --main-bg-color7: #F15A25;
  --main-bg-color8: #868687;
  --main-bg-color9: #F50A00;
  --main-bg-color10: #000000;
  --main-bg-color11: #86868780;
  --main-bg-color12: #9c9999a0;
  --main-bg-color13: #848181;
  --main-list-color0: #e1dddc;
  --main-list-color1: #e9e9e9;
  --main-list-color2: #848181;
  --main-hover-color0: #fe7c00;
  --main-hover-color1: #777475;
  --main-title-color0: #2d2d2d;
  --main-text-color0: #777475;
  --main-text-color1: #FFFFFF;
  --main-text-color2: #fe7c00;
  --main-text-color3: #444443;
  --main-text-color4: #31B700;
  --main-text-color5: #F50A00;
  --main-table-color0: #31B700;
  --main-table-color1: #fe7c00;
  --main-table-color2: #F50A00;
  --main-table-color4: #4aa0c5;
  --main-table-color3: #84818129;
  --main-bg-ping: #fe7c00;

  --main-chart-bg-color: #fe7b00d6;
  --main-chart-border-color: #F15A25;
  --main-indicator-color1: #677bbb;
  --main-indicator-color2: #a33054;
  --main-indicator-color3: #d57445;
  --main-indicator-color4: #c6a549;
  --main-calendar-color1: #27A737;
  --main-calendar-color2: #FE7C00;
  --main-calendar-color3: #4C4C4C;
  --main-bg-chat1: #77747572;
  --main-bg-chat2: #fe7b004d;
  --main-bg-chat3: #77747598;

  ;
}